import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import phLocationSquare from '../img/location-square-ph.svg';
import Loader from "./Loader";
import {Link} from "react-router-dom";
import {chunk} from "../utils/utils";
import Error from "./Error";
import {useProfile} from "../hooks/useProfile";

import {FaChevronRight,} from "react-icons/fa";

import {HiPlus,} from "react-icons/hi";

function LocationCard({location}) {
    return (<Link to={`/stanoviste/${location.slug}`}>
        <div className="card is-primary">
            <div className="card-content">
                <div className="media">
                    <div className="media-left">
                        <figure className="image is-128x128 is-square">
                            {location.image_url
                                ?
                                <img className="has-rounded-corners" src={location.thumbnail_url} alt={location.name}/>
                                :
                                <img className="has-rounded-corners" src={phLocationSquare} alt={location.name}/>
                            }
                        </figure>
                    </div>
                    <div className="media-content">
                        <p className="title is-6">
                            {location.name}
                        </p>
                        <p className="subtitle is-7 mb-4">{location.address_line_1}</p>
                        <p className="subtitle is-7">Počet včelstev: {location.hives_count}</p>
                    </div>
                    <div style={{marginTop: "auto"}}>
                        <FaChevronRight/>
                    </div>
                </div>
            </div>
        </div>
    </Link>)
        ;
}

export default function LocationsList() {

    const {loading, data, error} = useFetch(
        `${API_URL}/hive-locations/`
    );

    const {isPremium} = useProfile()

    if (data) {
        return (<>
            {data.length === 0 &&
            <p className="message is-primary p-6 has-text-centered"><h2 className="title is-6">
                Zatím nemáte žádné stanoviště.
            </h2></p>
            }
            <div className="columns">
                <div className="column">
                    {data.length > 0 &&
                    <h2 className="title is-4">Stanoviště ({data.length})</h2>}
                </div>
                <div className="column has-text-right">
                    {isPremium &&
                    <Link to="/nove-stanoviste/" className="button"><HiPlus className="mr-3"/>Založit
                        stanoviště</Link>}
                </div>
            </div>
            {chunk(data, 3).map(locations =>
                <div className="columns" key={locations[0].id}>
                    {locations.map(location =>
                        <div className="column is-4" key={location.id}>
                            <LocationCard location={location}/>
                        </div>
                    )}
                </div>
            )}
        </>)
    }

    if (loading) {
        return <Loader/>
    }

    if (error) {
        return <Error/>
    }
}
