import {useEffect} from "react";
import Breadcrumb, {BreadcrumbItem} from "../components/Breadcrumb";
import {FaHome} from "react-icons/fa";
import LocationsList from "../components/LocationsList";
import {usePosition} from "../index";
import {HoneyList} from "../components/HoneyList";
import {useFetch} from "../hooks/useFetch";
import {API_URL} from "../config";
import {Link} from "react-router-dom";


export default function Dashboard() {

    const {position, setPosition} = usePosition()

    useEffect(() => {
        document.title = "Chytrý včelař | Přehled stanovišť"
        setPosition({...position, ["HiveLocation"]: [1, 0]});
    }, []);

    const {data} = useFetch(`${API_URL}/hive-locations/`)

    return (
        <>
            <div className="container is-fluid mt-4">
                <div className="columns">
                    <div className="column is-2">
                        <div className="menu">
                            <p className="menu-label">
                                Včely
                            </p>
                            <ul className="menu-list">
                                <li><Link to="/muj-denik/" className="is-active">Stanoviště</Link></li>
                                {(data && data.length == 0) ?
                                    <li><Link style={{color: "#efefef"}}>Medobraní</Link></li>
                                    :
                                    <li><Link to="/medobrani/">Medobraní</Link></li>
                                }
                                {/*<li><Link to="/exporty/">Exporty</Link></li>*/}
                            </ul>
                            <p className="menu-label">
                                Nastavení
                            </p>
                            <ul className="menu-list">
                                <li><Link to="/profil/">Profil</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="column">
                        <LocationsList/>
                    </div>
                </div>
            </div>
        </>);
}
